.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px #9f9f9f;
  padding: 0.7rem 0;
}

.rowContainer {
  width: 100%;
  height: 100%;
}

.colContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navLeft {
  height: 100%;
  width: 8%;
  display: flex;
}

.navRight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navRight div {
  margin-right: 1rem;
}

.navLogo {
  height: 100%;
  width: 100%;
}

.navLogo:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .navLogo {
    width: 5%;
  }
}

@media only screen and (max-width: 992px) {
  .navLogo {
    width: 6%;
  }
}

@media only screen and (max-width: 768px) {
  .navLogo {
    width: 6%;
  }
}

@media only screen and (max-width: 599px) {
  .navLogo {
    width: 100%;
  }
}
