.loginContainer {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginTitle {
  text-align: center;
}

.loginInputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loginInput {
  width: 100%;
  height: 40px;
  font-size: 15px;
  margin: 1rem 0;
  font-family: "Outfit", sans-serif;
}

.loginBtn {
  text-align: center;
  width: 50%;
  font-family: "Outfit", sans-serif;
}

@media only screen and (max-width: 599px) {
  .loginBtn {
    width: 80%;
  }
}
