*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  background-color: #fafcff;
}

.ant-table table {
  font-size: 17px;
}

.success-btn {
  background: #4cbb7f !important;
  border: #4cbb7f !important;
}

.batch_id_text:hover {
  cursor: pointer;
}

table {
  width: 100% !important;
}
.monitorCol {
  display: flex !important;
  margin-top: 1rem !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
}

.monitorTitle {
  margin-top: 0.3rem;
  /* margin-right: 1rem; */
}
.card-container {
  display: flex;
  align-items: center;
  height: 42px;
  padding: 14.451px;
  gap: 14.451px;
  border-radius: 5.333px;
  background: var(--primary-black-100-ffffff, #fff);
  box-shadow: 5.333px 5.333px 16px 0px rgba(0, 0, 0, 0.08);
}
.side-tool-container {
  display: flex;
  align-items: center;
  height: 42px;
  padding: 14.451px;
  gap: 20.451px;
  border-radius: 5.333px;
  background: var(--primary-black-100-ffffff, #fff);
  box-shadow: 5.333px 5.333px 16px 0px rgba(0, 0, 0, 0.08);
}
.searchRequest {
  width: 25% !important;
  margin-bottom: 1rem;
}

.textAlign {
  text-align: center;
}

.margin-5 {
  margin: 5rem;
}

.margin-0 {
  margin: 0rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.margin-right {
  margin-right: 1rem;
}

code {
  background-color: rgb(238, 238, 238);
  border-radius: 5px;
  padding: 0.2rem;
}

input[type="file"]::file-selector-button {
  border: 0.1px solid rgb(189, 189, 189);
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #fff;
  transition: 0.3s;
}

input[type="file"]::file-selector-button:hover {
  border: 0.1px solid rgb(68, 156, 238);
  cursor: pointer;
}

.fixed-template-container {
  display: flex;
  overflow-x: scroll;
}

.fixed-template-container button {
  margin: 0.5rem 0.5rem 1rem 0;
}

/* css loader */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.monitor-filter {
  width: 150px;
  margin: 0 1rem;
}

.monitor-filter-datetime {
  width: 350px;
  margin: 0 1rem;
}

.container-flex {
  display: flex;
  flex-wrap: wrap;
}
.row-flex {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}
.row-flex-gap{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.horizontal-line{
  border-top: 1px solid #e8e8e8;
}
.card-style{
    display: flex;
    width: 280px;
    height: 100px;
    padding: 18px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid var(--secondary-blue-0-f-6-f-7-fe, #F6F7FE);
    background: var(--White, #FFF);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);  }
.tile-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
  justify-content: center;
  width:"500px";
}
.icon-style{
 color:"#4D4D4D" !important;
 font-size: "18px" !important;
}
.ml {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 0.3rem !important;
}

.mt {
  margin-top: 1.5rem !important;
}

.ant-table-thead > tr > th {
  background-color: #eaeaeb !important;
}
.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.container-space-between {
  display: flex;
  justify-content: space-between;
}

.custom-select.RERUN:not(.ant-select-customize-input) .ant-select-selector{
  background-color:#D2D6EF !important;
  color:#252F6A !important
}
.custom-select.PASSED:not(.ant-select-customize-input) .ant-select-selector{
  background-color:#EEF6E4 !important;
  color: #577E2A;
}
.custom-select.HOLD:not(.ant-select-customize-input) .ant-select-selector{
  background-color:#FFF4D1 !important;
  color: #9E7700;
}

.container-gap {
  display: flex;
  gap: 0.5rem;
}

.flex-wrap {
display: flex;
flex-direction: row;
flex-wrap: wrap;
min-width: 100px;
width: 100%;
}


.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 499px) {
  h1 {
    font-size: 1rem;
  }
  button {
    padding: 0.2rem 0.2rem !important;
  }

  .searchRequest {
    width: 100% !important;
  }

  .ant-table table {
    table-layout: auto !important;
  }
}
