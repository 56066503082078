.batchContainer {
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.batchBtn {
  margin-right: 1rem;
}

.modalInputTitle {
  margin-top: 1rem;
}

.requestContainer {
  margin-top: 1rem;
  width: 100%;
}

.createdBatchContainer {
  width: 100%;
}

.listComponent {
  display: flex;
  justify-content: space-between;
}

.modalContainer {
  height: 60vh;
  overflow-y: scroll;
  padding: 0 1.2rem 0 1.2rem;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

.stepNameBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #e6e7e8;
  padding: 10px;
  max-height: 50%;
  overflow-y: auto;
}

.stepNameDiv {
  display: flex;
  flex-direction: column;
}

.stepNameHeading {
  display: flex;
  font-style: italic;
  flex-wrap: wrap;
}

.fullWidth {
  width: 100%;
}


.cardRowContainer{
  border-radius: 0.375rem;
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
}



.cardInfoContainer{
  padding: 0.5rem 0.875rem 0.5rem 0.875rem;
  display: flex;
  flex-direction: row;
}


.fileIcon{
  color: #FFB11F;
  font-size: 2rem;
  padding: 0.125rem;
}


.fileDetailContainer{
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
}


.fileUpperDetail {
  color: #212121;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1rem;
}


.fileLowerDetail{
  color: #808080;
  font-size: 0.7rem;
  font-weight: 500;
}


.closeCircleOutlinedContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 0.875rem;
  color: red;
}


.uploadBtnContainer{
  display: flex;
  justify-content: flex-end;
  padding-top: 0.7rem;
}


.wingsSourceTypeContainer {
  display: flex;
  justify-content: space-between;
}


@media only screen and (max-width: 599px) {
  .batchContainer {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .batchBtn {
    margin: 0.1rem 0.2rem;
  }

  .dashboardTable {
    overflow-x: scroll !important;
  }
}
